/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import { FiGithub } from "@react-icons/all-files/fi/FiGithub";
import { useState } from "react";
import { useData } from "../../api/DataContext";
import { urlFor } from "../../client";
import { useLoading } from "../../helpers/LoadingContext";
import missingProjectImage from "../../assets/images/missing-project-image.png";
/////////////////////////////////////
// COMPONENT: PROJECTS
/////////////////////////////////////

const Projects = () => {
  const { setLoading } = useLoading();
  setLoading(false);

  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  );

  const params = new URLSearchParams(document.location.search);
  let year_filter = params.get("year") || null;

  if (year_filter === "all") {
    year_filter = "all";
  } else if (year_filter === "past") {
    year_filter = "past";
  } else if (
    year_filter === null ||
    isNaN(parseInt(year_filter)) ||
    parseInt(year_filter) <= currentYear - 3 ||
    parseInt(year_filter) > currentYear
  ) {
    window.location.href = "/work";
  }
  const { projectsData } = useData();

  return (
    <div>
      <div
        style={{ backgroundColor: "#1C1D20" }}
        className={`flex flex-col flex-wrap text-white text-left w-full h-full p-[37px] pl-[58px]`}
      >
        <div className="justify-between flex flex-col sm:flex-row max-w-[99%]">
          <div>
            <a href="/" className="m-0 text-[26px] leading-[38px] text-white">
              NEEL
              <br />
              PORTFOLIO
            </a>
          </div>
          <div>
            <div className="flex items-start w-[207px] h-[26px] gap-[38px] pt-5 sm:pt-0">
              <a
                href="/#about"
                className="text-white m-0 w-[41px] text-[15px] h-[26px]"
              >
                About
              </a>
              <a
                href="/work"
                className="text-white w-9 m-0 text-[15px] h-[26px]"
              >
                Work
              </a>
              <a
                href="/#contact"
                className="text-white m-0 w-[54px] text-[15px] h-[26px]"
              >
                Contact
              </a>
            </div>
          </div>
        </div>

        <div>
          <br />
          <br />
          <br />
        </div>

        <div className="flex flex-col sm:flex-row justify-center sm:space-x-20 sm:space-y-0 space-y-10">
          <div className="sm:w-2/5">
            <h2 className="text-5xl">
              Unleashing ideas, <br />
              shaping the future
            </h2>
          </div>
          <div className="sm:w-2/5">
            <p className="">
              Welcome to my projects, a showcase of my passion for design and
              development. Each project displayed here is a reflection of my
              dedication to creating visually stunning and user-friendly designs
              that leave a lasting impression. <br />
              <br />
              From concept to launch, I pour my heart and soul into every
              project, carefully crafting each element with precision and
              attention to detail. As you browse through my diverse range of
              projects, you will see the breadth of my skills and experience.
            </p>
          </div>
        </div>
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
      <div>
        <br />
        <br />
      </div>
      <div className="w-full h-full">
        <div className="lg:max-w-5xl max-w-md mx-auto">
          <h2 className="text-5xl">Work Cases</h2>
        </div>
        <br />
        <br />
        <br />
        <div className="flex flex-wrap gap-20 justify-center">
          {projectsData &&
            projectsData
              .filter((project: any) => {
                if (year_filter === "all") {
                  return true;
                } else if (year_filter === "past") {
                  return currentYear - 3 >= project.year;
                } else if (year_filter != null) {
                  return project.year === parseInt(year_filter);
                }
              })
              .map(
                (
                  {
                    title,
                    Tags,
                    mainImage,
                    github,
                    themeColor,
                    year,
                    websitePreview,
                    link,
                  }: any,
                  index: number
                ) => (
                  <div key={index}>
                    <div
                      className={`sm:w-[30rem] w-[25rem] h-[25rem] sm:h-[30rem] flex items-center justify-center ${
                        index % 2 === 0 ? "" : "mt-16"
                      }`}
                      style={{ backgroundColor: themeColor || "#767676bb" }}
                    >
                      <img
                        src={urlFor(
                          websitePreview
                            ? websitePreview
                            : mainImage
                            ? mainImage
                            : missingProjectImage
                        ).url()}
                        alt={title}
                        className="w-[400px] h-[300px] object-contain"
                      />
                    </div>
                    <br />
                    <br />
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <a href={link || ""} target="_blank">
                          <h2 className="text-3xl">{title}</h2>
                        </a>
                        <h2 className="text-lg">{year}</h2>
                      </div>
                      <hr style={{ backgroundColor: "#4f4f4f" }} />
                      <div className="pt-2 flex justify-between">
                        <h2>
                          {Tags &&
                            Tags.map((tag: string, index: number) => (
                              <span key={tag} className="text-sm">
                                {tag}
                                {index !== Tags.length - 1 && (
                                  <span>&nbsp;&amp;&nbsp;</span>
                                )}
                              </span>
                            ))}
                        </h2>
                        {github && (
                          <a target="_blank" href={github}>
                            <FiGithub className="text-xl" />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                )
              )}
        </div>
      </div>
      <div>
        <footer
          style={{ backgroundColor: "#1C1D20" }}
          className="flex items-center justify-center mt-10 h-16"
        >
          <p className="text-white">Copyright ©️ {currentYear} NEEL</p>
        </footer>
      </div>
    </div>
  );
};

/////////////////////////////////////
// EXPORTING PROJECTS
/////////////////////////////////////

export default Projects;
